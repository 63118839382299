import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { segmentAction } from "../hooks/useSegmentTrack"

function Seo({
  description = "",
  lang = "en",
  meta = [],
  title,
  ogImage,
  ogUrl,
  includeZohoTracking = false,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description

  useEffect(() => {
    segmentAction.page(title)
  }, [title])

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="images/png"
        sizes="32x32"
        href="favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="favicon-16x16.png"
      />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ef4765" />
      <meta name="msapplication-TileColor" content="#ef4765" />
      {/* Can make this variable if we'd like */}
      <meta name="theme-color" content="#ffffff"></meta>      
      {includeZohoTracking && process.env.GATSBY_ZOHO_SALESIQ_ENABLED && <script type="text/javascript" id="zsiqchat">
        {`var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "a06ea1e2c875b259cd595fe18620c491f3e7fd724f93e4f7577ffa480e0b48b71a2010ab7b6727677d37b27582c0e9c4", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`}
      </script>}
    </Helmet>
  )
}

export default Seo
